<template>
    <div class="pricing-container">
        <div class="pricing-header">
            <h1>一站式AI创意设计服务平台</h1>
            <p style="padding: 30px 0px;">海量正版内容 · 正版商用授权 · 高清无水印下载 · 图像制作共享 · AI智能设计 · 企业级本地效</p>
        </div>
        <div class="juzhong" style="width: 65%;height: auto;margin: 0 auto;margin-top: 20px;">
            <el-row :gutter="20">
                <el-col :span="6" v-for="(plan, index) in plans" :key="index">
                    <div :class="['plan', plan.class]">
                        <div class="plan-header">
                            <div :style="plan.bg" style="width: calc(100% - 40px);height: 110px;padding: 20px;border-radius: 10px;">
                                <h2 :style="index == 3 ?'color: #ffd092':''">{{ plan.name }}</h2>
                                <p  :style="index == 3 ?'color: hsla(0,0%,100%,.8)':'color:#505a71'" style="line-height: 40px;">{{ plan.description }}</p>
                                <div style="width: 100%;height: 24px;display: flex;margin-top: 10px;">
                                    <div v-for="(ite,indx) in plan.inputList" :key="indx"  :style="plan.color1" style="width: 66px;line-height: 24px;border-radius: 6px;font-size: 12px;font-weight: 400;text-align: center;margin-right: 12px;cursor: pointer;">{{ite}}</div>
                                </div>
                            </div>

                            <div class="price" style="height: 100px;">
                               <div v-if="index <= 2 " style="line-height: 100px;">
                                   <span>￥<span style="font-size: 24px;font-weight: bold;padding: 0 5px;">{{ plan.price }}</span></span>
                                   <span>/月</span>
                               </div>
                                <div v-if="index == 3" >
                                    <el-tooltip
                                        effect="light"
                                        placement="right">
                                        <div slot="content" style="width: 184px;height: 230px;color: rgb(93, 111, 143);font-weight: 400;">
                                            <div style="width: 184px;height: 200px;">
                                                <img style="width: 100%;height: 100%;margin: 0 auto;margin-top: 10px;cursor: pointer;"  src="@/assets/imgs/vip/xcxcode4.jpg">
                                            </div>
                                            <div style="width: 100%;line-height: 30px;text-align: center;font-size: 14px;font-weight: 500;">扫码联系客服开通</div>
                                        </div>
                                        <img style="width: 80px;height: 80px;margin: 0 auto;margin-top: 10px;cursor: pointer;"  src="@/assets/imgs/vip/xcxcode4.jpg">
                                    </el-tooltip>
                                </div>
                            </div>
                            <div v-if="index == 0 " @click="openWin(index)" @mouseenter="handleMouseOver(plan.asc)" @mouseleave="handleMouseLeave" :style="plan.p1"  style="width: 90%;line-height: 38px;border-radius: 8px;text-align: center;font-size: 14px;margin: 0 auto;font-weight: 600;cursor: pointer;">
                                <span>免费体验</span>
                            </div>
                            <div v-if="index == 1" @click="openWin(index)" @mouseenter="handleMouseOver(plan.asc)" @mouseleave="handleMouseLeave" :style="activeTab == 'Standard' ? plan.p2 : plan.p1"  style="width: 90%;line-height: 38px;border-radius: 8px;text-align: center;font-size: 14px;margin: 0 auto;font-weight: 600;cursor: pointer;">
                                <span v-if="user.isVip == 0">立即开通</span>
                                <span v-else>立即续费</span>
                            </div>
                            <div v-if="index == 2" @click="openWin(index)" @mouseenter="handleMouseOver(plan.asc)" @mouseleave="handleMouseLeave" :style="activeTab == 'Collaboration' ? plan.p2 : plan.p1"  style="width: 90%;line-height: 38px;border-radius: 8px;text-align: center;font-size: 14px;margin: 0 auto;font-weight: 600;cursor: pointer;">
                                <span v-if="user.isVip == 0">立即开通</span>
                                <span v-else>立即续费</span>
                            </div>
                            <div v-if="index == 3" @click="openWin(index)" @mouseenter="handleMouseOver(plan.asc)" @mouseleave="handleMouseLeave" :style="plan.p1"  style="width: 90%;line-height: 38px;border-radius: 8px;text-align: center;font-size: 14px;margin: 0 auto;font-weight: 600;cursor: pointer;">
                                扫码预约开通
                            </div>
                        </div>
                        <div class="plan-features">
                            <div v-for="(item, index) in plan.titleList" :key="index" style="width: 90%;height: auto;margin: 0 auto;margin-top: 12px;margin-bottom: 20px;">
                                <span style="color: #1b2337;font-weight: 600;font-size: 14px;line-height: 32px;">{{ item.title }}</span>
                                <div v-for="(ite, indx) in item.features" :key="indx" style="display: flex;">
                                    <div style="width: 245px;height: 20px;color: #505a71;font-size: 12px;line-height: 30px;margin-bottom: 12px;">
                                        {{ite}}
                                    </div>
                                    <div>
                                        <img style="width: 16px;height: 16px;"  src="@/assets/imgs/vip/pre-check.fef3b1ec.svg">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div style="width: 65%;height: auto;margin: 0 auto;">
            <el-row>
                <el-col :span="24">
                    <h2 style="text-align: center; margin-top:60px;margin-bottom:20px;font-size: 36px;color: #1b2337;font-weight: 600;height: 100px;">会员权益对比</h2>
                </el-col>
            </el-row>
            <div style="width: 100%;height: 66px;background: #F5F7FD;display: flex;font-size: 14px;">
                <div style="width: 25%;line-height: 66px;text-align: center;"> 授权范围对比</div>
                <div style="width: 25%;line-height: 66px;text-align: center;"> 免费版</div>
                <div style="width: 25%;line-height: 66px;text-align: center;"> 通用版</div>
                <div style="width: 25%;line-height: 66px;text-align: center;"> 协作版</div>
                <div style="width: 25%;line-height: 66px;text-align: center;"> 旗舰版</div>
            </div>
            <div v-for="(item,index) in tableData" :key="index">
                <div  style="width: 100%;height: 86px;display: flex;font-size: 14px;" :style="index < tableData.length - 1 ? 'border-bottom: 1px solid #EBEEF5':''">
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.feature}}</div>
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.free}}</div>
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.standard}}</div>
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.cooperation}}</div>
                    <div style="width: 25%;line-height: 66px;text-align: center;"> {{item.custom}}</div>
                </div>
            </div>
        </div>

        <div style="width: 65%;height: auto;margin: 0 auto;">
            <el-row>
                <el-col :span="24">
                    <h2 style="text-align: center; margin-top:60px;font-size: 36px;color: #1b2337;font-weight: 600;height: 100px;">常见问题</h2>
                </el-col>
            </el-row>
            <!-- FAQ Section -->
            <div v-for="(faq, index) in faqs" :key="index" class="faq-item" @click="toggleFAQ(index)">
                <div class="faq-question">
                    <span>{{ faq.question }}</span>
                    <span class="arrow" :class="{ open: faq.open }">▼</span>
                </div>
                <div v-if="faq.open" class="faq-answer">
                    {{ faq.answer }}
                </div>
            </div>corp
        </div>
        <!--开通-->
        <div class="dialog-container">
            <el-dialog :visible.sync="isPersonalWin" width="1200px" custom-class="login-dialog" class="custom-dialog">
                <personal-win :type="type"/>
            </el-dialog>
        </div>
        <div class="dialog-container">
            <el-dialog :visible.sync="isCorpWin" width="1200px" custom-class="login-dialog" class="custom-dialog">
                <corp-win :type="type"/>
            </el-dialog>
        </div>
        <!--续费-->
        <div class="dialog-container">
            <el-dialog :visible.sync="isRenewPersonalWin" width="1200px" custom-class="login-dialog" class="custom-dialog">
                <personal-renew-win :type="type"/>
            </el-dialog>
        </div>
        <div class="dialog-container">
            <el-dialog :visible.sync="isRenewCorpWin" width="1200px" custom-class="login-dialog" class="custom-dialog">
                <corp-renew-win :type="type"/>
            </el-dialog>
        </div>
        <!--扩容-->
        <div class="dialog-container">
            <el-dialog :visible.sync="isSpaceSizeWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <spaceSizeWin :type="3"/>
            </el-dialog>
        </div>
        <div class="dialog-container">
            <el-dialog :visible.sync="isSubAccountWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <subAccountWin :type="3"/>
            </el-dialog>
        </div>
        <!--支付成功-->
        <div class="dialog-container">
            <el-dialog :visible.sync="isPaySuccessWin" width="1200px" custom-class="login-dialog" class="custom-dialog" top="10vh">
                <pay-success-win />
            </el-dialog>
        </div>
    </div>
</template>

<script>
import PersonalWin from "./buy/personalWin.vue";
import corpWin from "./buy/corpWin.vue";
import PersonalRenewWin from "./renew/personalRenewWin.vue";
import corpRenewWin from "./renew/corpRenewWin.vue";
import spaceSizeWin from "./expand/spaceSizeWin.vue";
import subAccountWin from "./expand/subAccountWin.vue";
import paySuccessWin from "./paySuccessWin.vue";
export default {
    components: {PersonalWin,corpWin,PersonalRenewWin,corpRenewWin,spaceSizeWin,subAccountWin,paySuccessWin},
    data() {
        return {
            user:{},
            type:0,
            activeTab: '',  // 用于记录当前激活的标签
            plans: [
                {
                    name: '免费版',
                    asc: 'Free',
                    description: '适用于个人体验学习使用',
                    price: '0',
                    buttonText: '免费体验',
                    buttonType: 'primary',
                    titleList:[
                        {
                            title:'内容创作权益',
                            features: [
                                '享海量正版内容的智能创意',
                                '智能模板',
                                'AI工具',
                                '增值服务包'
                            ],
                        },
                        {
                            title:'智能工具权益',
                            features: [
                                '智能抠图(设计也内) 2张/月',
                                'AI创作工具 10 贴贴',
                                'AI工具'
                            ],
                        },
                        {
                            title:'增值服务包',
                            features: [
                                '3GB 云存储空间'
                            ],
                        }
                    ],
                    inputList:['免费模版','智能抠图','AI工具'],
                    class: 'free-plan',
                    bg:'background:linear-gradient(104deg,#ecf1fb 1.63%,#d5dcec 99.05%)',
                    p1:' color: #0773fc;border:1px solid #0773fc;',
                    color1:'color:#505a71;background: hsla(0,0%,100%,.8);border: 0.5px solid #fff;',
                },
                {
                    name: '通用版',
                    asc: 'Standard',
                    description: '适用于个人使用',
                    price: '6',
                    buttonText: '立即开通',
                    buttonType: 'primary',
                    titleList:[
                        {
                            title:'内容创作权益',
                            features: [
                                '享海量正版内容的智能创意',
                                '智能模板',
                                'AI工具',
                                '增值服务包'
                            ],
                        },
                        {
                            title:'智能工具权益',
                            features: [
                                '智能抠图(设计也内) 2张/月',
                                'AI创作工具 10 贴贴',
                                'AI工具'
                            ],
                        },
                        {
                            title:'增值服务包',
                            features: [
                                '3GB 云存储空间'
                            ],
                        }
                    ],
                    inputList:['无水印下载','智能设计','商用无忧'],
                    class: 'standard-plan',
                    bg:'background:linear-gradient(96deg,#cee8fe 24.89%,#8ec0ff 91.29%)',
                    p1:'color: #FFFFFF;background:linear-gradient(90deg,#3d92ff .09%,#0773fc 101.47%);',
                    p2:'color: #FFFFFF;background:linear-gradient(to right, #2B84F5, #0B6AE3);',
                    color1:'color:#0773fc;background: hsla(0,0%,100%,.8);border: 0.5px solid #fff;',
                },
                {
                    name: '协作版',
                    asc: 'Collaboration',
                    description: '适用于2-10人团队协作',
                    price: '199',
                    buttonText: '立即开通',
                    buttonType: 'warning',
                    titleList:[
                        {
                            title:'内容创作权益',
                            features: [
                                '享海量正版内容的智能创意',
                                '智能模板',
                                'AI工具',
                                '增值服务包'
                            ],
                        },
                        {
                            title:'智能工具权益',
                            features: [
                                '智能抠图(设计也内) 2张/月',
                                'AI创作工具 10 贴贴',
                                'AI工具'
                            ],
                        },
                        {
                            title:'增值服务包',
                            features: [
                                '3GB 云存储空间'
                            ],
                        }
                    ],
                    inputList:['多人协作','品牌管理','批量设计'],
                    class: 'collaboration-plan',
                    bg:'background:linear-gradient(90deg,#fceee1,#ffd5b6)',
                    p1:'color: #6b3d1e;background:linear-gradient(90deg,#fceee1,#ffc9a0),linear-gradient(270deg,#4d65a4,#5f78b7);',
                    p2:'color: #6b3d1e;background: linear-gradient(to right, #FDD8B4, #F4B27F);',
                    color1:'color:#6b3d1e;background: hsla(0,0%,100%,.8);border: 0.5px solid #fff;',
                },
                {
                    name: '旗舰版',
                    asc: 'Enterprise',
                    description: '适用于大中型企业/跨职能业务子公司',
                    price: '咨询客服',
                    buttonText: '预约开通',
                    buttonType: 'default',
                    titleList:[
                        {
                            title:'内容创作权益',
                            features: [
                                '享海量正版内容的智能创意',
                                '智能模板',
                                'AI工具',
                                '增值服务包'
                            ],
                        },
                        {
                            title:'智能工具权益',
                            features: [
                                '智能抠图(设计也内) 2张/月',
                                'AI创作工具 10 贴贴',
                                'AI工具'
                            ],
                        },
                        {
                            title:'增值服务包',
                            features: [
                                '3GB 云存储空间'
                            ],
                        }
                    ],
                    inputList:['安全合规','品牌管控','资产管理'],
                    class: 'enterprise-plan',
                    bg:'background:linear-gradient(90deg,#3b404a 1%,#07080a)',
                    p1:'color: #ffd092;background:linear-gradient(270deg,#2b2c31,#4e4f53)',
                    p2:'color: #6b3d1e;background: linear-gradient(to right, #494A4E, #2D2E33);',
                    color1:'color:#ffd092;backgroud:hsla(0,0%,100%,.2);border: 0.5px solid hsla(0,0%,100%,.3)',
                }
            ],
            tableData: [
                {
                    feature: '价格',
                    free: '免费使用',
                    standard: '¥6/月起',
                    cooperation: '¥199/年/人起',
                    custom: '专业个性化服务'
                },
                {
                    feature: '起购人数',
                    free: '✗',
                    standard: '1人',
                    cooperation: '2人',
                    custom: '可定制'
                },
                {
                    feature: '可购人数上限',
                    free: '✗',
                    standard: '1人',
                    cooperation: '10人',
                    custom: '无上限'
                },
                {
                    feature: '授权类型',
                    free: '仅限个人学习交流',
                    standard: '个人/企业商用授权 *1个（可追加）',
                    cooperation: '企业商用授权 *1个（可追加）',
                    custom: '企业商用授权 *1个（可选购）'
                }
            ],
            faqs: [
                {
                    question: '01. 通用版、协作版、旗舰版之间的区别是什么？',
                    answer: '通用版: 适合单人使用，提供海量的商用模板素材，授权主体包含：个人或企业授权，支持高清无水印下载等功能的授权版。协作版：支持10人以内的在线协作共享，授权为企业授权，支持批量设计，内容标签管理，享受多于通用版的功能与权益。 旗舰版：享受协作版全部功能外，额外拥有品牌管控、安全合规、数据可视化等功能权益。同时，支持授权主体与账号定制，适用于大型企业/跨地域多子公司等。 联系专属顾问',
                    open: false
                },
                {
                    question: '02. 我是企业员工，我自己使用可以购买通用版个人吗？',
                    answer: '是的，企业员工可以购买通用版个人使用。',
                    open: false
                },
                {
                    question: '03. 如何申请发票？需要多长时间？',
                    answer: '申请发票流程如下：登录账户 -> 进入订单管理 -> 申请发票。一般需要3-5个工作日处理。',
                    open: false
                },
            ],
            isPersonalWin:false,
            isCorpWin:false,
            isRenewPersonalWin:false,
            isRenewCorpWin:false,
            isSpaceSizeWin:false,
            isSubAccountWin:false,
            isPaySuccessWin:false,
        }
    },

    created() {
        var that = this;
        this.$EventBus.$EventBus.$off("payMsg")
        this.$EventBus.$on("payMsg", (data) => {
            this.$nextTick(() => {
                that.isPersonalWin = false;
                that.isCorpWin = false;
                that.isRenewPersonalWin = false;
                that.isRenewCorpWin = false;
                that.isSpaceSizeWin = false;
                that.isSubAccountWin = false;
                that.isPaySuccessWin = true;
            })
        })
    },
    mounted() {
        this.getUserInfo();
    },
    methods:{
        getUserInfo() {
           var that = this;
           that.newApi.getUserInfo({}).then((res) => {
                if (res.isSuccess == 1) {
                    console.log("userInfo")
                    that.user = res.data;
                    if (that.user.isVip == 0) {
                        this.type = 1;
                    } else if (that.user.isVip == 1 ||that.user.isVip == 2) {
                        this.type = 2;
                    }
                }
           })
        },
        handleMouseOver(tab) {
            this.activeTab = tab;  // 鼠标移入时设置激活的标签
        },
        handleMouseLeave() {
            this.activeTab = '';  // 鼠标移出时取消激活的标签
        },
        toggleFAQ(index) {
            this.faqs[index].open = !this.faqs[index].open;
        },
        openWin(index){
            var that = this;
            if(that.type == 0){
                that.utils.err("类型错误");
                return false;
            }

            if(that.user.realNameStatus != 2){
                that.utils.err('请先实名认证');
                return false;
            }

            //1开通2续费
            if(that.type == 1){
                if(index == 1){
                    if(that.user.userType == 1){
                        that.isPersonalWin = true;
                    }else{
                        that.utils.err('您不是个人账号');
                    }

                }else if(index == 2){
                    if(that.user.userType == 2){
                        that.isCorpWin = true;
                    }else{
                        that.utils.err('您不是企业账号');
                    }
                }
            }else if(that.type == 2){
                if(index == 1){
                    if(that.user.userType == 1){
                        that.isRenewPersonalWin = true;
                    }else{
                        that.utils.err('您不是个人账号');
                    }
                }else if(index == 2){
                    if(that.user.userType == 2){
                        that.isRenewCorpWin = true;
                    }else{
                        that.utils.err('您不是企业账号');
                    }
                }
            }

        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.pricing-container {
    text-align: center;
    padding: 40px 20px;
    background: #FFFFFF;
    font-family: 'Roboto', sans-serif;
}

.pricing-header h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #333;
}

.pricing-header p {
    font-size: 16px;
    color: #666;
}

.pricing-tables {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.plan {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 40px rgba(42,49,67,.06);
    transition: transform 0.3s ease;
    text-align: left;
    border:1px solid #fff;
}

.plan:hover {
    transform: translateY(-10px);
}

.plan-header {
    margin-bottom: 20px;
}

.plan-header h2 {
    font-size: 22px;
    font-weight: 600;
    color: #333;
}

.plan-header p {
    font-size: 14px;
    color: #999;
}

.price {
    color: #333;
    text-align: center;
}

h2 {
    font-weight: bold;
}

/* FAQ Section */
.faq-section {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.faq-item {
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background 0.3s;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.faq-item:hover {
    background: #f0f0f0;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #333;
}

.faq-answer {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
    text-align: left;
}

.arrow {
    transition: transform 0.3s;
}

.arrow.open {
    transform: rotate(180deg);
}
/deep/ .dialog-container .el-dialog__wrapper {
    border-radius: 10px !important;
}

/deep/ .dialog-container .el-dialog {
    border-radius: 10px !important;
}

</style>
